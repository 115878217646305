// lib/cookieUtils.ts

export const setCookie = (name: string, value: string, days: number): void => {
    if (typeof document !== 'undefined') {
        const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
    }
};

export const getCookie = (name: string): string | null => {
    if (typeof document !== 'undefined') {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? decodeURIComponent(match[2]) : null;
    }
    return null;
};
